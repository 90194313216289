import request from '@/utils/request'
export function addNewTextualMaterial(data) {
    return request({
        url: '/api/textualMaterial/addNewTextualMaterial',
        method: 'post',
        headers: {'Content-Type':'multipart/form-data'},
        data
    })
}
export function updateTextIsDelete(data) {
    return request({
        url: '/api/textualMaterial/updateTextIsDelete',
        method: 'post',
        data
    })
}

export function updateTextIsDelete2Zero(data) {
    return request({
        url: `/api/textualMaterial/updateTextIsDelete2Zero/${data}`,
        method: 'post',
    })
}
export function selectTextById(data) {
    return request({
        url: '/api/textualMaterial/selectTextById',
        method: 'post',
        data
    })
}
export function updateText(data) {
    return request({
        url: '/api/textualMaterial/updateText',
        method: 'post',
        data
    })
}
export function updateTextExamine(data) {
    return request({
        url: `/api/textualMaterial/updateTextExamine/${data}`,
        method: 'post',
    })
}


export function selectAllTexts() {
    return request({
        url: '/api/textualMaterial/selectAllTexts',
        method: 'post',

    })
}

export function selectOrdinaryTexts() {
    return request({
        url: '/api/textualMaterial/selectOrdinaryTexts',
        method: 'post',

    })
}

export function selectVipTexts() {
    return request({
        url: '/api/textualMaterial/selectVipTexts',
        method: 'post',

    })
}
export function getWordCloud(data) {
    return request({
        url: '/api/textualMaterial/getWordCloud',
        method: 'post',
        data
    })
}

export function getTextSummary(data) {
    return request({
        url: '/api/textualMaterial/getTextSummary',
        method: 'post',
        data
    })
}

export function selectAllTextsWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectAllTextsWithPage',
        method: 'post',
        data
    })
}

export function selectOrdinaryTextsWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectOrdinaryTextsWithPage',
        method: 'post',
        data
    })
}
export function selectVipTextsWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectVipTextsWithPage',
        method: 'post',
        data
    })
}
export function selectEngineeringTextWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectEngineeringTextWithPage',
        method: 'post',
        data
    })
}
export function selectScienceTextWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectScienceTextWithPage',
        method: 'post',
        data
    })
}
export function selectLiteratureTextWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectLiteratureTextWithPage',
        method: 'post',
        data
    })
}
export function selectLawTextWithPage(data) {
    return request({
        url: '/api/textualMaterial/selectLawTextWithPage',
        method: 'post',
        data
    })
}

export function textOrdinaryCount(data) {
    return request({
        url: '/api/textualMaterial/textOrdinaryCount',
        method: 'post',
        data
    })
}

export function textVipCount(data){
    return request({
        url: '/api/textualMaterial/textVipCount',
        method: 'post',
        data
    })
}
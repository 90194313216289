<template>
    <router-view/>
</template>
<script setup>
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";
    import {useStore} from 'vuex'
    import {getTeacherBannedMessage} from '@/api/message'
    import {getUser2TeacherMessage, getUserBannedMessage} from "@/api/message";
    import {socket} from "@/utils/webSocket";
    import {onMounted,ref,onBeforeMount,nextTick} from 'vue'


    const loading = ref(true);
    const websocketCount = ref(-1);
    const queryCondition = ref({ type: 'message',content:'' });
    const router = useRouter();
    const store = useStore();
    // console.log(store.getters.getAdminStatus)
    router.beforeEach((to, from, next) => {
        //访问需要授权的页面时，如果没有登录，则跳转到登录界面
        //管理员路由守卫
        if (to.path == '/adminMine') {
            if (!store.getters.getAdminStatus || window.sessionStorage.getItem("vuex")==null) {
                ElMessage.error('您还没有登录，请先登录');
                return next('/adminLogin');
            }
        }

        if (from.path == '/adminMine' && to.path == '/adminLogin') {
            if (store.getters.getAdminStatus) {
                // console.log("据链家链家解决")
                return {path: '/adminMine'}
            }
        }

        //普通用户路由守卫，只有登陆后才能去课程观看页面
        if (to.path=='/studyCourse' || to.path=='/commentArea' || to.path=='/studyText' || to.path=='/allCourse' ||to.path=='/YanZhiDao'){
            if ((!store.getters.getStatus) || window.sessionStorage.getItem("vuex")==null){
                ElMessage.error("您还没有登录请前往登录");
                return next("/login")
            }
        }
        //防止重复返回登陆页面
        if (from.path == '/' && to.path == '/login' ){
            if (store.getters.getStatus ){
                return next("/")
            }
        }
        if (from.path =='/login' && to.path=='/'){
            if (to.query.refresh){
                window.location.reload();
            }

        }



        next();
    })

    const websocketOnMessage = (event) => {
        const info = JSON.parse(event.data);
        switch (info.type) {
            case 'heartbeat':
                console.log(info)
                socket.websocketState = true;
                // console.log(".........")
                break;
            case 'message':
                console.log('message')
                loading.value = true;
                nextTick(() => {
                    consumeMessage(info);
                });
                break;
            case 'error':
                loading.value = false;
                break;
        }
    };

    const consumeMessage = (info) => {
        console.log(info);
        if (info.state ==0){
            ElMessage.info(info.content);
        }else if (info.state==1){
            // console.log('.........')
            ElMessage.warning(info.content);
            store.commit("resetUser");
            socket.websocketOnClose();
            router.push({path:'/login'});
        }

    };
    onBeforeMount(() => {
        if (store.getters.getStatus){
            // console.log('进来了吗？？？')
            socket.initWebSocket('wss://zzj.zipfine.com/notice/'+store.getters.getId);
            socket.websocket.onmessage = websocketOnMessage;
        }
        // // console.log('进来了吗？？？')
        // socket.initWebSocket('ws://localhost:8081/notice/teacher:'+store.getters.getId);
        // socket.websocket.onmessage = websocketOnMessage;

    });


    // 解决ERROR ResizeObserver loop completed with undelivered notifications.

    //问题的

    const debounce = (fn, delay) => {

        let timer = null;

        return function () {

            let context = this;

            let args = arguments;

            clearTimeout(timer);

            timer = setTimeout(function () {

                fn.apply(context, args);

            }, delay);

        }

    }

    // 解决ERROR ResizeObserver loop completed with undelivered notifications.

    //问题的

    const _ResizeObserver = window.ResizeObserver;

    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

        constructor(callback) {

            callback = debounce(callback, 16);

            super(callback);

        }

    }

</script>
<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>

<template>
    <!--导航栏-->
    <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            :ellipsis="false"
    >
        <div class="flex-grow" />
        <el-menu-item index="0"><img src="../assets/frontlogo.png" style="height: 50px; margin-top: 5px;width: 90px"></el-menu-item>
        <el-menu-item index="1" @click="jumpToMain">主页</el-menu-item>
        <el-sub-menu index="2">
            <template #title>课程</template>
            <router-link :to="{path:'/allCourse',query:{name:'全部文本',refresh: true}}" class="router-link"
                         style="text-decoration: none;">
                <el-menu-item index="2-1" class="router-link">
                    <el-icon>
                        <icon-menu/>
                    </el-icon>
                    <span @click="">全部文本</span>
                </el-menu-item>
            </router-link>
            <router-link :to="{path:'/allCourse',query:{name:'全部课程',refresh: true}}" class="router-link"
                         style="text-decoration: none;">
                <el-menu-item index="2-3" class="router-link">
                    <el-icon>
                        <icon-menu/>
                    </el-icon>
                    <span>全部视频</span>
                </el-menu-item>
            </router-link>
            <el-sub-menu index="2-4">
                <template #title>视频分类</template>
                <router-link :to="{path:'/allCourse',query:{name:'工学课程',refresh: true}}" class="router-link"
                              style="text-decoration: none;">
                    <el-menu-item index="2-4-5" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span @click="">普通视频</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'精品课程',refresh: true }}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-4-6" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span @click="">精品视频</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'工学课程',refresh: true }}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-4-1" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>工学视频</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'理学课程',refresh: true}}" class="router-link"
                              style="text-decoration: none;">
                    <el-menu-item index="2-4-2" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>理学视频</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'文学课程',refresh: true}}" class="router-link"
                              style="text-decoration: none;">
                    <el-menu-item index="2-4-3" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>文学视频</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'法学课程',refresh: true}}" class="router-link"
                              style="text-decoration: none;">
                    <el-menu-item index="2-4-4" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>法学视频</span>
                    </el-menu-item>
                </router-link>
            </el-sub-menu>


<!--            ----------------------------------------------------->
            <el-sub-menu index="2-5">
                <template #title>文本分类</template>
                <router-link :to="{path:'/allCourse',query:{name:'普通文本',refresh: true}}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-5-5" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span @click="">普通文本</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'精品文本',refresh: true }}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-5-6" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span @click="">精品文本</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'工学文本',refresh: true }}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-5-1" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>工学文本</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'理学文本',refresh: true}}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-5-2" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>理学文本</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'文学文本',refresh: true}}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-5-3" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>文学文本</span>
                    </el-menu-item>
                </router-link>
                <router-link :to="{path:'/allCourse',query:{name:'法学文本',refresh: true}}" class="router-link"
                             style="text-decoration: none;">
                    <el-menu-item index="2-5-4" class="router-link">
                        <el-icon>
                            <icon-menu/>
                        </el-icon>
                        <span>法学文本</span>
                    </el-menu-item>
                </router-link>
            </el-sub-menu>
        </el-sub-menu>
<!--        <el-menu-item index="1">课程</el-menu-item>-->
        <div class="flex-grow" />
<!--        <el-menu-item index="2">Processing Center</el-menu-item>-->

        <el-menu-item index="3" @click="jumpMine" v-if="(store.getters.getStatus)&&((username||username!=''))">
            个人中心
        </el-menu-item>
        <el-menu-item index="4" @click="jumpLogin" v-else>
            登录 | 注册
        </el-menu-item>
        <el-menu-item index="5" @click="jumpPersonSpace" v-if="(store.getters.getStatus)&&((username||username!=''))">
            个人空间
        </el-menu-item>
<!--        v-if="is_teacher==2"-->
        <el-menu-item index="6" @click="jumpCreationCenter" v-if="(store.getters.getStatus)&&((username||username!='')) && store.getters.getIsTeacher==2" >
            创作中心
        </el-menu-item>
        <el-menu-item index="7" @click="jumpYanZhiDao" v-if="(store.getters.getStatus)&&((username||username!=''))" >
            燕知道
        </el-menu-item>
        <el-menu-item index="7" style="margin: auto">
            <el-popover
                    placement="bottom"
                    title="微信小程序二维码"
                    :width="200"
                    trigger="hover"
                    style="margin: auto"
            >
                <div >
                    <img
                            src="../assets/上线版.jpg"
                            style="width: 100%"
                    />
                </div>
                <template #reference>
                    <el-button class="m-2">燕易学小程序</el-button>
                </template>
            </el-popover>


        </el-menu-item>
        <div class="flex-grow" />
    </el-menu>
</template>

<script setup>
   import {ref} from "vue";
   import {useRouter} from  'vue-router'
   import {useStore} from 'vuex'
   import { ElMessage, ElMessageBox } from 'element-plus'
   import {
       Document,
       Menu as IconMenu,
       Location,
       Setting,
   } from '@element-plus/icons-vue'
   const activeIndex = ref('');
   let router = useRouter();
   const jumpLogin = ()=>{
        router.push({path:'/login'});
   }
   const store = useStore();

   const username = store.getters.getUsername
   const is_teacher = store.getters.getIsTeacher;
   // !store.getters.getUsername||store.getters.getUsername==''
   const jumpMine = ()=>{
       router.push({path:'/mine/personalInfo'})
   }
   const jumpToMain = ()=>{
       router.push({path:'/'})
   }
   const jumpPersonSpace = ()=>{
       // console.log(store.getters.getId)
       const tempid = store.getters.getId;
       router.push({path:'/personspace',query:{id:tempid,refresh: true}})
   }
   const jumpCreationCenter =()=>{
       router.push({path:"/creationcenter/uploadNewMaterials"})
   }
   const jumpYanZhiDao = ()=>{
       router.push({path:'/YanZhiDao'})
   }
   const jumpYanYiXue = ()=>{

   }
   // const jumpToFree = ()=>{
   //     router.push({path:'/allCourse',query:{name:'普通课程'}})
   // }
   // const jumpToVip =()=>{
   //     router.push({path:'/allCourse',query:{name:'精品课程'}})
   // }
</script>

<style scoped>
    .flex-grow {
        flex-grow: 1;
    }
    .el-menu-demo{
        width: 100%; /* 将组件宽度设置为100% */
        margin-left: 0; /* 可选：如果有默认的左边距，请将其设置为0 */
        margin-right: 0; /* 可选：如果有默认的右边距，请将其设置为0 */
    }
    .router-link:hover {
        color: #42b983;
    }
</style>
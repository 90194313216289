
const course = {
    state:{
        id: 0,
        name: "",
        url: "",
        type: "",
        label: [],
        vip: ""
    },
    getters:{
        getCourseId(state){
            return state.id;
        },
        getCourseUrl(state){
            return state.url;
        },
        getCourseName(state){
            return state.name;
        },
        getCourseType(state){
            return state.type;
        },
        getCourseLabel(state){
            return state.label;
        },
        getCourseVip(state){
            return state.vip;
        }
    },
    mutations:{
        saveCourse(state,course){
            state.id = course.id;
            state.name = course.name;
            state.type = course.type;
            state.label = course.label;
        }
    },
    actions:{

    }
}
export default course
<template>
    <span style="margin-left: 3px;" @click="changeCommentShow">回复</span>
    <el-collapse-transition>
        <div v-if="rootCommentShow">
            <div class="comment-edit-box d-flex">
                <form>
                    <textarea class="comment-content sizeDiv" v-model="commentContent" placeholder="回复: " maxLength="255" @keyup="countNum"></textarea>
                    <div class="comment-operate-box">
                        <div class="comment-operate-l">
						<span class="tip">
                            还能输入
							<em>{{ countWordNum }}</em>
							个字符
						</span>
                        </div>
                        <div class="comment-operate-r">
                            <el-button type="danger" size="mini" style="border-radius: 20px;" @click="saveComment">评论</el-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </el-collapse-transition>
</template>

<script setup>
    import {ref,reactive,defineProps,onMounted} from 'vue'
    import {useStore} from 'vuex'
    import {ElMessage} from "element-plus";
    import {addComment} from '@/api/comment'
    const props = defineProps(['rootParentId','comment','courseId','closeReplay','getCommentList','course'])
    const rootParentId = props.rootParentId;
    const comment = props.comment;
    // const parentId=props.parentId;
    const courseId = props.courseId
    // const mytextareaComment=ref()
    const commentContent = ref()
    const store = useStore();
    const rootCommentShow = ref(false);
    const countWordNum = ref(255)
    const countNum =()=> {
        if (commentContent.value){
            countWordNum.value = 255 - commentContent.value.length;
        }
    }
    const changeCommentShow = ()=>{
        rootCommentShow.value = !rootCommentShow.value;
    }
    const commentModel = {
        content:'',
        courseId:'',
        textId:'',
        parentId:null,
        rootParentId:null,
        userId: store.getters.getIsTeacher!=2?store.getters.getId:0,
        teacherId:store.getters.getIsTeacher==2?store.getters.getId:0
    }
    const saveComment = ()=>{

        // console.log(commentModel)
        if (!commentContent.value){
            ElMessage.warning("评论内容不能为空");
            return;
        }
        const content = commentContent.value.trim();
        if (!content) {
            ElMessage.warning("评论内容不能为空");
            return;
        }

        commentModel.content = commentContent.value;
        // commentModel.courseId = courseId;
        if (props.course.textualType == '' || props.course.textualType==null){
            commentModel.courseId = props.course.id;
        }else {
            commentModel.textId = props.course.id;
        }
        commentModel.parentId = props.comment.id;
        commentModel.rootParentId = rootParentId;
        // console.log(commentModel)
            //调用api插入
            addComment(commentModel).then((res)=>{
                commentContent.value = null;
                // props.closeReplay();
                rootCommentShow.value = false;
                props.getCommentList();
                location.reload();

            }).catch((error) => {
                ElMessage.error("评论失败");
                console.error(error);
            });
    }
</script>

<style scoped>
    .comment-edit-box {
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .d-flex {
        display: flex !important;
    }
    .comment-operate-box {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding: 8px 16px 0 16px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: display .3s ease-in-out;
    }
    .comment-operate-l, .comment-operate-r {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }
    .comment-operate-l .tip {
        font-size: 12px;
        color: #999aaa;
        line-height: 17px;
    }
    .tip em {
        color: #222226;
        margin: 0 4px;
        font-style: normal;
    }
    .comment-edit-box {
        padding-left: 40px;
        margin-bottom: 16px;
        margin-top: 8px;
    }
    .comment-edit-box form {
        width: 100%;
        position: relative;
        background: rgba(245, 246, 247, 0.8);
        border-radius: 8px;
        padding: 14px 0;
    }
    .comment-box.comment-box-new2 .comment-content {
        display: block;
        width: 100%;
        background: rgba(248, 249, 251, 0.8);
        border: none;
        padding: 0 16px;
        border-radius: 4px;
        resize: none;
        height: 88px;
        font-size: 14px;
        line-height: 22px;
        box-sizing: border-box;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: auto;
        outline: none;
    }
    .sizeDiv{
        width: 30%;
        height: 50%;
    }
</style>
<template>
    <el-card :body-style="{ padding: '0px',height:'280px'}" shadow="hover" @click="jumpToCourse"
             style="position: relative">
        <div class="coverSizeDiv">
            <!--精品课程才有的标签-->
            <img
                    src="../assets/VIP.png"
                    class="logoImg"
                    v-show="props.course&&props.course.vip=='VIP'"
            />
            <div v-show=" course.textualType=='pdf'" class="textImg">
                <svg  t="1712766148685" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4323" width="100%" height="100%"><path d="M145.6 0C100.8 0 64 36.8 64 81.6v860.8C64 987.2 100.8 1024 145.6 1024h732.8c44.8 0 81.6-36.8 81.6-81.6V324.8L657.6 0h-512z" fill="#8C181A" p-id="4324"></path><path d="M960 326.4v16H755.2s-100.8-20.8-97.6-107.2c0 0 3.2 91.2 96 91.2H960z" fill="#6B0D12" p-id="4325"></path><path d="M657.6 0v233.6c0 27.2 17.6 92.8 97.6 92.8H960L657.6 0z" fill="#FFFFFF" p-id="4326"></path><path d="M302.4 784h-52.8v65.6c0 6.4-4.8 11.2-12.8 11.2-6.4 0-11.2-4.8-11.2-11.2V686.4c0-9.6 8-17.6 17.6-17.6h59.2c38.4 0 60.8 27.2 60.8 57.6 0 32-22.4 57.6-60.8 57.6z m-1.6-94.4h-51.2v73.6h51.2c22.4 0 38.4-14.4 38.4-36.8s-16-36.8-38.4-36.8z m166.4 171.2h-48c-9.6 0-17.6-8-17.6-17.6v-156.8c0-9.6 8-17.6 17.6-17.6h48c59.2 0 99.2 41.6 99.2 96s-38.4 96-99.2 96z m0-171.2h-41.6v148.8h41.6c46.4 0 73.6-33.6 73.6-75.2 1.6-40-25.6-73.6-73.6-73.6z m260.8 0h-92.8V752h91.2c6.4 0 9.6 4.8 9.6 11.2s-4.8 9.6-9.6 9.6h-91.2v76.8c0 6.4-4.8 11.2-12.8 11.2-6.4 0-11.2-4.8-11.2-11.2V686.4c0-9.6 8-17.6 17.6-17.6h99.2c6.4 0 9.6 4.8 9.6 11.2 1.6 4.8-3.2 9.6-9.6 9.6z" fill="#FFFFFF" p-id="4327"></path></svg>
            </div>
            <div v-show=" course.textualType=='docx'" class="textImg">
            <svg t="1712771718153" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5347" width="100%" height="100%"><path d="M967.111111 281.6V910.222222c0 62.577778-51.2 113.777778-113.777778 113.777778H170.666667c-62.577778 0-113.777778-51.2-113.777778-113.777778V113.777778c0-62.577778 51.2-113.777778 113.777778-113.777778h514.844444L967.111111 281.6z" fill="#4F6BF6" p-id="5348"></path><path d="M581.262222 755.626667h59.363556L739.555556 439.04h-59.335112z" fill="#FFFFFF" p-id="5349"></path><path d="M685.511111 224.711111V0L967.111111 281.6H742.4c-31.288889 0-56.888889-25.6-56.888889-56.888889" fill="#243EBB" p-id="5350"></path><path d="M640.625778 755.626667h-59.363556l-98.929778-277.020445h59.335112zM442.737778 755.626667h-59.363556L284.444444 439.04h59.335112z" fill="#FFFFFF" p-id="5351"></path><path d="M383.374222 755.626667h59.363556l98.929778-277.020445h-59.335112z" fill="#FFFFFF" p-id="5352"></path></svg>
            </div>
                <img
                    :src="props.course&&props.course.coverUrl"
                    class="image"
            />

        </div>
        <div style="padding: 14px">
            <span style="font-weight: bold">{{props.course&&props.course.name}}</span>
            <div class="typeAndTeacherDiv">
                <span>{{props.course&&props.course.type}} &nbsp;{{props.course&&props.course.tchname}}</span>
            </div>
            <div class="bottom">
                {{props.course&&props.course.intro}}
            </div>
        </div>

    </el-card>
</template>
<script setup>
    import {useRouter} from 'vue-router'
    //子组件获取值
    const props = defineProps(['course','video','text'])
    const router = useRouter();
    const course = props.course;
    const video = props.video;
    const text = props.text;

    // console.log(props.course)
    const jumpToCourse = () => {
        if (course.textualType=='' || course.textualType==null){
            router.push({path: '/studyCourse', query: props.course})
        }else if (course.textualType=='docx' || course.textualType=='pdf'){
            router.push({path:'/studyText',query: props.course})
        }

    }

</script>

<style scoped>
    .bottom {
        margin-top: 13px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .image {
        width: 100%;
    }

    .logoImg {
        width: 25%;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .textImg{
        width: 20%;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .typeAndTeacherDiv {
        margin-top: 3px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
    }
    .coverSizeDiv{
        height: 140px;
        display: flex;
    }
</style>
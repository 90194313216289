

const admin = {
    state:{
        id: 0,
        username: "",
        password:"",
        status: false
    },
    getters:{
        getAdminId(state){
            return state.id
        },
        getAdminUsername(state){
            return state.username
        },
        getAdminPassword(state){
            return state.password
        },
        getAdminStatus(state){
            return state.status;
        },
    },
    mutations:{
        setAdminStatus(state,status){
            state.status = status;
        },
        saveAdmin(state,user){
            state.id = user.id
            state.username = user.username
            state.password = user.password
        },
    },
    actions:{

    }
}
export default admin
<template>
    <div>
        <div class="comment-line-box">
            <div class="comment-list-item">
                <router-link :to="{path:'/personspace',query:{id:props.comment.userId || props.comment.teacherId }}" class="router-link">
                    <el-avatar icon="el-icon-user-solid" :size="35" style="width: 38px;" :src="props.comment.avatarUrl" v-show="props.comment.avatarUrl !=null && props.comment.avatarUrl!=''"/>
                    <el-avatar v-show="props.comment.avatarUrl ==null || props.comment.avatarUrl==''" :size="35"
                               src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
                </router-link>
                <div class="right-box">
                    <div class="new-info-box clearfix">
                        <div class="comment-top">
                            <div class="user-box">
                                    <span class="comment-name">{{ props.comment.userNickname || props.comment.teacherName}}</span>
                                <el-tag size="mini" type="danger" v-show="props.comment.teacherId == props.course.tid" style="margin-left: 5px;">老师</el-tag>
                                <span class="date">{{ props.comment.createTime }}</span>
                                <div class="opt-comment"
                                     v-show="store.getters.getId==props.course.tid || store.getters.getId == props.comment.userId || store.getters.getId==props.comment.teacherId"
                                >
                                    <i class="el-icon-delete"><el-icon><Delete /></el-icon></i>
                                    <span style="margin-left: 3px;" @click="removeComment(props.comment)">删除</span>
                                    <!--                                    <i class="el-icon-chat-round" style="margin-left: 10px;"><el-icon><ChatRound /></el-icon></i>-->
                                    <!--                                    <span style="margin-left: 3px;" @click="changeCommentShow">回复</span>-->
                                    <!--                                    <replay :rootParentId="props.comment.id" :comment="props.comment" :courseId="props.course.id" :getCommentList="props.getCommentList" :closeReplay="changeCommentShow" style="margin-top: 5px;"></replay>-->

                                </div>
                            </div>
                        </div>
                        <div class="comment-center">
                            <div class="new-comment">{{ props.comment.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 回复框 -->
            <div class="replayDiv">
                <i class="el-icon-chat-round" style="margin-left: 10px;"><el-icon><ChatRound /></el-icon></i>
                <!-- 回复框 -->
                <replay :rootParentId="rootParentId" :comment="props.comment"  :courseId="props.course.id" :getCommentList="props.getCommentList" :closeReplay="changeCommentShow" :course="props.course" style="margin-top: 5px;"></replay>
            </div>
            <!--            <replay :rootParentId="props.comment.id" :comment="props.comment" :showReplay="rootCommentShow" :courseId="props.course.id" :getCommentList="props.getCommentList" :closeReplay="changeCommentShow" style="margin-top: 5px;"></replay>-->
        </div>
    </div>
</template>

<script setup>
    import {ref,reactive} from 'vue'
    import {
        Delete,
        ChatRound
    } from '@element-plus/icons-vue'
    import {deleteComment} from "@/api/comment";
    import {ElMessage, UploadProps} from 'element-plus'
    import {useStore} from 'vuex'

    const store = useStore();
    const props = defineProps(['comment','course','getCommentList']);

    const removeComment = (comment)=>{
        // console.log(store.getters.getTeacherId===props.course.tid)
        if (store.getters.getId==props.course.tid || store.getters.getId == comment.userId || store.getters.getId==comment.teacherId){
            deleteComment(comment).then((res)=>{
                ElMessage.success("删除成功");
                props.getCommentList();
            })
        }else {
            ElMessage.error("您没有权限删除");
        }
    }
</script>

<style scoped>
    .comment-line-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        position: relative;
        margin-top: 8px;
    }
    .comment-line-box .comment-list-item {
        display: flex;
        width: 100%;
    }
    .comment-line-box .right-box {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
        margin-left: 8px;
    }
    .comment-line-box .right-box .new-info-box {
        width: 100%;
    }
    .comment-line-box .right-box .new-info-box .comment-top {
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        margin-bottom: 4px;
        line-height: 20px;
        font-size: 14px;
    }
    .comment-line-box .right-box .new-info-box .user-box {
        -webkit-box-flex: 1;
        flex: 1;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        right: 0;
        top: 0;
        padding-right: 8px;
    }
    .comment-name {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }
    .date {
        font-size: 14px;
        color: #777888;
        margin-left: 5px;
    }
    .opt-comment {
        line-height: 20px;
        height: 20px;
        /*display: none;*/
        position: absolute;
        right: 0;
        top: 0;
        padding-left: 16px;
        background: #fff;
    }
    .opt-comment:hover {
        cursor: pointer;
    }
    .new-comment {
        font-size: 14px;
        color: #222226;
        line-height: 22px;
        word-break: break-word;
    }
    .clearfix{
        display: flex;
        flex-direction: column;
        /*align-items: flex-start;*/
    }
    .comment-center{
        display: flex;
    }
    .replayDiv:hover{
        cursor: pointer;
    }
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Menu from '@/components/Menu'
import UNCForm from '@/components/CreationCenter/UploadNewCourseForm'

// import EditCourse from '@/components/CreationCenter/EditCourseDialog'
// import CoursePreview from '@/components/CreationCenter/CoursePreview'
import CourseCard from '@/components/CourseCard'
import root from '@/components/Replay/RootComponents'
import child from '@/components/Replay/ChildComponents'
import replay from '@/components/Replay/Replay'
import firstReplay from '@/components/Replay/FirstReplay'
import courseInfo from '@/components/CourseInfo'
//导入element-plus组件
import ElementPlus from 'element-plus'
import "./assets/iconfont/iconfont.css"
import 'element-plus/dist/index.css'
import './assets/global.css'
//注册全局组件
const app=createApp(App)
//注册组件 名称 短中线， 在使用过程中 要与注册的名称一摸一样（大小写都要一样）
//组测名式驼峰，使用时可以用驼峰（要与注册名称一致）， 用短中线也可以，大小写也不区分
app.use(store).use(router).mount('#app')
app.component("Menu",Menu)
app.component("UNCForm",UNCForm)
// app.component("EditCourse",EditCourse)
// app.component("CoursePreview",CoursePreview)
app.component("CourseCard",CourseCard)
app.component("root",root)
app.component("child",child)
app.component("replay",replay)
app.component("firstReplay",firstReplay)
app.component("courseInfo",courseInfo)
//使用elementplus
app.use(ElementPlus)

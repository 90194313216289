

const user = {
    state:{
        id: 0,
        username: "",
        password:"",
        age:18,
        sex:"",
        vip:"",
        nickname:'',
        avatarUrl:'',
        phone:"",
        isTeacher:0,
        status: false
    },
    getters:{
        getId(state){
            return state.id
        },
        getUsername(state){
            return state.username
        },
        getPassword(state){
            return state.password
        },
        getAge(state){
            return state.age
        },
        getSex(state){
            return state.sex
        },
        getVip(state){
            return state.vip
        },
        getNickname(state){
            return state.nickname;
        },
        getAvatarUrl(state){
            return state.avatarUrl;
        },
        getPhone(state){
            return state.phone;
        },
        getStatus(state){
            return state.status;
        },
        getIsTeacher(state){
            return state.isTeacher;
        }
    },
    mutations:{
        saveUser(state,user){
            state.id = user.id
            state.username = user.username
            state.password = user.password
            state.age = user.age
            state.sex = user.sex
            state.vip = user.vip
            state.nickname=user.nickname
            state.avatarUrl = user.avatarUrl
            state.phone = user.phone
            state.isTeacher = user.isTeacher
        },
        saveStatus(state,status){
            state.status = status
        },
        saveAvatarUrl(state,avatarUrl){
            state.avatarUrl = avatarUrl;
        },
        saveNickname(state,nickname){
            state.nickname = nickname;
        },
        saveIsTeacher(state,isTeacher){
            state.isTeacher = isTeacher
        },
        resetUser(state){
            state.id = 0
            state.username = ""
            state.password = ""
            state.age = 18
            state.sex = ""
            state.vip = ""
            state.nickname = ""
            state.avatarUrl = ""
            state.phone = ""
            state.status = false;
            state.isTeacher = 0;
        }
    },
    actions:{

    }
}
export default user
import {createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("@/views/Home")
    },
    // {
    //     path:'/test',
    //     name: 'test',
    //     component:()=>import('@/views/ZzjTest.vue')
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import("@/views/LoginAndRegister")
    },

    {
        path: '/mine',
        name: 'mine',
        component: () => import("@/views/PersonCenterViews/Mine"),
        children: [
            {
                path: '/mine/tearegister',
                name: 'teacherregister',
                component: () => import("@/views/PersonCenterViews/TeacherRegister")
            },
            // {
            //     path: '/mine/course',
            //     name: 'course',
            //     component: () => import("@/views/CreationCenter/CourseControl")
            // },
            {
                path: '/mine/personalInfo',
                name: 'personalInfo',
                component:()=>import("@/views/PersonCenterViews/PersonalInfo")
            },
            {
                path: '/mine/accountManagement',
                name: 'accountManagement',
                component:()=>import("@/views/PersonCenterViews/AccountManagement")
            },
            // {
            //     path: '/mine/examiningCourse',
            //     name: 'examiningCourse',
            //     component:()=>import('@/views/CreationCenter/ExaminingCourse')
            // },

        ]
    },
    {
        path: '/adminLogin',
        name: 'adminLogin',
        component:()=>import("@/views/Admin/AdminLogin")
    },
    {
        path: '/adminMine',
        name: 'adminMine',
        component:()=>import("@/views/Admin/AdminMine"),
        children: [
            {
                path: '/adminMine/adminCourse',
                name: 'adminCourse',
                component:()=>import('@/views/Admin/AdminCourse')
            },
            {
                path: '/adminMine/adminTeacher',
                name: 'adminTeacher',
                component:()=>import('@/views/Admin/AdminTeacher')
            },
            {
                path: '/adminMine/adminStudent',
                name: 'adminStudent',
                component:()=>import('@/views/Admin/AdminStudent')
            },
            {
                path: '/adminMine/adminExamineCourse',
                name: 'adminExamineCourse',
                component:()=>import("@/views/Admin/AdminExamineCourse")
            },
            {
                path: '/adminMine/selectAllDeletingCourse',
                name: 'selectAllDeletingCourse',
                component:()=>import('@/views/Admin/AdminDeleteCourse')
            },
            {
                path: '/adminMine/adminHome',
                name: 'adminHome',
                component:()=>import('@/views/Admin/AdminHome')
            },
            {
                path: '/adminMine/adminTeacherRegister',
                name: 'adminTeacherRegister',
                component:()=>import('@/views/Admin/AdminTeacherRegister')
            },
            {
                path: '/adminMine/adminRecommend',
                name: 'adminRecommend',
                component:()=>import('@/views/Admin/AdminRecommend')
            },
        ]
    },

    {
        path: '/studyCourse',
        name: 'studyCourse',
        component:()=>import('@/views/CourseWatch/CourseWatchMain'),
        children: [
            // {
            //     path: '/studyCourse/courseArea',
            //     name: 'courseArea',
            //     component:()=>import('@/views/CourseWatch/CourseArea'),
            // },

        ]
    },
    {
      path: '/studyText',
      name: 'studyText',
      component:()=>import('@/views/CourseWatch/TextualMaterialWatchMain')
    },
    {
        path: '/commentArea',
        name: 'commentArea',
        component:()=>import('@/views/CourseWatch/CommentArea'),
    },
    {
        path: '/allCourse',
        name: 'allCourse',
        component:()=>import('@/views/AllCourse')
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component:()=>import('@/views/ResetPassword')
    },

    {
        path: "/personspace",
        name: "personspace",
        component:()=>import("@/views/PersonSpaceViews/PersonSpaceMain"),
    },
    {
        path: "/creationcenter",
        name: "creationcenter",
        // component:()=>import("@/views/CreationCenter/CourseControl")
        component:()=>import("@/views/CreationCenter/CreationCenterHome"),
        children: [
            {
                path: "/creationcenter/CourseControl",
                name: "course",
                component:()=>import("@/views/CreationCenter/CourseControl"),
            },
            {
                path: "/creationcenter/examiningCourse",
                name: "examingcourse",
                component:()=>import("@/views/CreationCenter/ExaminingCourse"),
            },
            {
                path: "/creationcenter/uploadNewMaterials",
                name: "uploadNewMaterials",
                component:()=>import("@/views/CreationCenter/UploadNewMaterials"),
            }
        ]
    },

    {
        path: "/YanZhiDao",
        component:()=>import("@/views/BaiDuChat/ChatMainArea")
    },
    // {
    //     path: "/creationcenter/examiningCourse/test",
    //     component:()=>import("@/components/test")
    // }


    // {
    //   path: '/coursemenu',
    //   name: 'coursemenu',
    //   component:()=>import("@/components/UploadNewCourseForm")
    // }
    //配置路由 路径 名称和组件对象，这样 把路径、名称 映射到组件上，vue-router组件就可以通过路径或者名称找到对应组件并显示出来
]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    // routes
    history: createWebHashHistory(),
    routes
})


export default router

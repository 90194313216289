<template>
    <div class="comment-line-box" v-for="childComment in  props.childComments">
        <div class="comment-list-item">
            <router-link :to="{path:'/personspace',query:{id:childComment.userId || childComment.teacherId }}" class="router-link">
                <el-avatar icon="el-icon-user-solid" :size="35" style="width: 38px;" :src="childComment.avatarUrl" v-show="childComment.avatarUrl !=null && childComment.avatarUrl!=''"></el-avatar>
                <el-avatar v-show="childComment.avatarUrl ==null || childComment.avatarUrl==''" :size="35"
                           src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
            </router-link>
            <div class="right-box">
                <div class="new-info-box clearfix">
                    <div class="comment-top">
                        <div class="user-box">
                            <span class="comment-name">{{ childComment.userNickname || childComment.teacherName }}</span>
                            <el-tag size="mini" type="danger" v-show="childComment.teacherId == course.tid" style="margin-left: 5px;">老师</el-tag>
                            <span class="text">回复</span>
                            <span class="nick-name">{{ parentComment.userNickname || parentComment.teacherName }}</span>
                            <span class="date">{{ childComment.createTime }}</span>
                            <div class="opt-comment"
                                 v-show="store.getters.getId==props.course.tid || store.getters.getId == childComment.userId || store.getters.getId==childComment.teacherId"
                            >
                                <i class="el-icon-delete"><el-icon><Delete /></el-icon></i>
                                <span style="margin-left: 3px;" @click="removeComment(childComment)">删除</span>
                                <!--                                <i class="el-icon-chat-round" style="margin-left: 10px;"><el-icon><ChatRound /></el-icon></i>-->
                                <!--                                &lt;!&ndash; 回复框 &ndash;&gt;-->
                                <!--                                <replay :rootParentId="rootParentId" :comment="childComment"  :courseId="props.course.id" :getCommentList="props.getCommentList" :closeReplay="changeCommentShow" style="margin-top: 5px;"></replay>-->
                                <!--                            <span style="margin-left: 3px;" @click="ss">回复</span>-->
                            </div>
                        </div>
                    </div>
                    <div class="comment-center">
                        <div class="new-comment">{{ childComment.content }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 回复框 -->
        <!--        <replay :rootParentId="rootParentId" :comment="childComment" :showReplay="rootCommentShow" :courseId="props.course.id" :getCommentList="props.getCommentList" :closeReplay="changeCommentShow" style="margin-top: 5px;"></replay>-->
        <div class="replayDiv">
            <i class="el-icon-chat-round" style="margin-left: 10px;"><el-icon><ChatRound /></el-icon></i>
            <!-- 回复框 -->
            <replay :rootParentId="rootParentId" :comment="childComment"  :courseId="props.course.id" :getCommentList="props.getCommentList" :closeReplay="changeCommentShow" :course="props.course" style="margin-top: 5px;"></replay>
        </div>
        <!-- 嵌套递归 -->
        <child :childComments="childComment.child" :parentComment="childComment" :course="props.course" :rootParentId="rootParentId" :getCommentList="props.getCommentList"></child>
    </div>

</template>

<script setup>
    import {ref,reactive} from 'vue'
    import {
        Delete,
        ChatRound,
    } from '@element-plus/icons-vue'
    import {deleteComment} from "@/api/comment";
    import {ElMessage} from "element-plus";
    import {useStore} from 'vuex'

    const store = useStore();
    const props = defineProps(['childComments','course','parentComment','rootParentId','getCommentList']);
    const childComments = props.childComments;
    const course = props.course;
    const parentComment = props.parentComment;
    const rootParentId = props.rootParentId;
    // let rootCommentShow = reactive(false);
    // const rootCommentShow = ref(false);
    // const changeCommentShow = ()=>{
    //     rootCommentShow.value = !rootCommentShow.value;
    //     // console.log(rootCommentShow.value)
    //     // rootCommentShow.value = !rootCommentShow;
    //     // rootCommentShow.value =false;
    // }
    const removeComment = (comment)=>{
        // console.log(store.getters.getId)
        // console.log(props.course.tid)
        // console.log(store.getters.getId==props.course.tid)
        if (store.getters.getId==props.course.tid || store.getters.getId == comment.userId || store.getters.getId==comment.teacherId){
            // console.log("...................................")
            // console.log(comment)
            deleteComment(comment).then((res)=>{
                ElMessage.success("删除成功");
                props.getCommentList();
            })
        }else {
            ElMessage.error("您没有权限删除");
        }
    }

</script>

<style scoped>
    .comment-line-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        position: relative;
        margin-top: 8px;
    }
    .comment-line-box .comment-list-item {
        display: flex;
        width: 100%;
    }
    .comment-line-box .right-box {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
        margin-left: 8px;
    }
    .comment-line-box .right-box .new-info-box {
        width: 100%;
    }
    .comment-line-box .right-box .new-info-box .comment-top {
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        margin-bottom: 4px;
        line-height: 20px;
        font-size: 14px;
    }
    .comment-line-box .right-box .new-info-box .user-box {
        -webkit-box-flex: 1;
        flex: 1;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        right: 0;
        top: 0;
        padding-right: 8px;
    }
    .comment-name {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }
    .date {
        font-size: 14px;
        color: #777888;
        margin-left: 5px;
    }
    .opt-comment {
        line-height: 20px;
        height: 20px;
        /*// display: none;*/
        position: absolute;
        right: 0;
        top: 0;
        padding-left: 16px;
        background: #fff;
    }
    .opt-comment:hover {
        cursor: pointer;
    }
    .replayDiv:hover{
        cursor: pointer;
    }
    .new-comment {
        font-size: 14px;
        color: #222226;
        line-height: 22px;
        word-break: break-word;
    }
    .replay-box {
        padding-left: 32px;
    }
    .comment-top .text {
        color: #999aaa;
        margin: 0 6px;
        min-width: 28px;
    }
    .comment-top .nick-name {
        color: #777888;
        max-width: 105px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .clearfix{
        display: flex;
        flex-direction: column;
        /*align-items: flex-start;*/
    }
    .comment-center{
        display: flex;
    }

</style>
import { createStore, } from 'vuex'
import user from "@/store/user";
import teacher from "@/store/teacher";
import createPersistedState from 'vuex-persistedstate'
import course from "@/store/course";
import admin from "@/store/admin";

export default createStore({
  //存储全局数据
  state: {
  },
  //通过计算获取store里的内容获取数据
  //只读
  getters: {
    //定义函数，其它组件可以直接调用getCount函数获取state中的值，好处，防止直接修改state的值

  },
  //定义对state各种操作，不能执行异步操作，简单直接赋值操作
  mutations: {
    //定义函数，作用是修改state中变量的值


  },
  //定义对state各种操作，action无法直接修改state，需要在mutation里更新
  //mutation 不支持异步，，所以需要在action里请求后端api
  actions: {

    },
  //加载多个state文件，防止一个state文件过长
  modules: {
    user,course,admin,
  },
  //6、插件
  plugins:[
    createPersistedState({
      //指定存储位置（默认位置是local storage）
      //sessionStorage表示会话存储，当浏览器关闭后，存储就消失了
      //localStorage 表示本地存储，当浏览器关闭后 ，存储不消失，再次打开网站 数据恢复
      //storage:window.sessionStorage,
      //reducer: 指定需要缓存的状态属性

      //存储方式是sessionStorage
      storage:sessionStorage,
      // paths:["admin"]
    })
  ]
})

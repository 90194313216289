import request from '@/utils/request'
export function selectCourseComments (data) {
    return request({
        url: `/api/comment/selectCourseComments/${data}`,
        method: 'post',
    })
}

export function addComment (data) {
    return request({
        url: '/api/comment/addComment',
        method: 'post',
        data
    })
}

export function deleteComment(data) {
    return request({
        url: '/api/comment/deleteComment',
        method: 'post',
        headers: {'Content-Type':'application/json'},
        data
    })
}
export function selectTextComments(data) {
    return request({
        url: '/api/comment/selectTextComments',
        method: 'post',
        data
    })
}
<!--预览课程信息-->
<template>
    <el-button size="small" @click="handleEdit">课程信息</el-button>

    <!--编辑弹出框-->
    <el-dialog v-model="editDialogVisible"
               @close="closeReset"
               append-to-body="true">
        <el-form
                ref="ruleFormRef"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                :label-position="labelPosition"
                class="demo-ruleForm"
                :size="formSize"
                status-icon
        >
            <el-form-item label="课程名" prop="name">
                <el-input v-model="ruleForm.name" disabled/>
            </el-form-item>
            <el-form-item label="课程介绍" prop="intro">
                <el-input v-model="ruleForm.intro"
                          type="textarea"
                          disabled/>
            </el-form-item>

            <el-form-item label="课程类别" prop="type" >
                <el-radio-group v-model="ruleForm.type" disabled>
                    <el-radio border label="理学"/>
                    <el-radio border label="工学"/>
                    <el-radio border label="文学"/>
                    <el-radio border label="法学"/>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="课程标签">
                <el-tag
                        v-for="tag in dynamicTags"
                        :key="tag"
                        class="mx-1"
                        :disable-transitions="false"
                        @close="handleClose(tag)"
                >
                    {{ tag }}
                </el-tag>

            </el-form-item>

            <!--switch标签-->
            <el-form-item label="精品设置">
                <el-radio-group v-model="ruleForm.vip" disabled>
                    <el-radio border label="ORDINARY"/>
                    <el-radio border label="VIP"/>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script setup>
    import {editCourse, updateCourse} from "@/api/course";
    import {nextTick, reactive, ref} from "vue";
    const editDialogVisible = ref(false);
    const props = defineProps(['scope'])
    let nameTemp = '';
    let typeTemp = '';
    let labelTemp = '';
    let vipTemp ='';
    let introTemp = '';
    //点开隐藏框
    const handleEdit = () => {
        // console.log(index, row)
        // console.log(props.scope)

        ruleForm.id = props.scope.id;
        ruleForm.name = props.scope.name;
        ruleForm.type = props.scope.type;
        ruleForm.vip =  props.scope.vip;
        // ruleForm.label = props.scope.label;
        ruleForm.intro = props.scope.intro;
        dynamicTags.value = props.scope.label.split('&')


        editDialogVisible.value = true;
    }

    //编辑操作
    //隐藏框内参数
    const inputValue = ref()
    const dynamicTags = ref([])
    const inputVisible = ref(false)
    const InputRef = ref()
    //表单验证和表单数据处理
    const formSize = ref('large')
    const labelPosition = ref('top')
    const ruleFormRef = ref()

    //表单包含的信息
    const ruleForm = reactive({
        id: 0,
        name: '',
        type: '',
        label: '',
        vip:'',
        intro:'',
    })



    //tag标签处理
    const handleClose = (tag) => {
        dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1)
    }

    const showInput = () => {
        inputVisible.value = true
        nextTick(() => {
            InputRef.value.input.focus()
        })
    }

    const handleInputConfirm = () => {
        if (inputValue.value) {
            dynamicTags.value.push(inputValue.value)
        }
        inputVisible.value = false
        inputValue.value = ''
    }

</script>

<style scoped>

</style>
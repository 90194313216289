<template>
    <!--    v-if="!is_uploading"-->
    <div v-loading="loading" >
        <div class="uploadDiv" v-if="!is_uploading" >
            <el-upload
                    ref="uploadRef"
                    class="upload-demo"
                    drag
                    name="videoFile"
                    multiple
                    :accept="accept"
                    :data="ruleForm"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="onUpload"
            >
                <!--            :action="action"-->
                <!--            :on-remove="removeFile"-->
                <!--                        :on-success="uploadSuccess"-->
                <!--                                    :show-file-list="false"-->
                <el-icon class="el-icon--upload">
                    <upload-filled/></el-icon>
                <div class="el-upload__text">
                    拖拽 或 <em>点击上传文件</em>
                </div>
                <template #tip>
                    <div class="el-upload__tip" v-show="is_video==true">
                        视频文件最大150MB
                    </div>
                    <div class="el-upload__tip" v-show="is_video==false">
                        文档文件最大1MB
                    </div>
                </template>
            </el-upload>
        </div>
        <!--    v-if="is_uploading"-->
        <div  class="info_class" v-show="is_uploading">
<!--            <el-progress-->
<!--                    type="line"-->
<!--                    :percentage="videoUploadPercent"-->
<!--                    style="margin-top: 30px"-->
<!--            ></el-progress>-->

            <el-form
                    ref="ruleFormRef"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="120px"
                    :label-position="labelPosition"
                    class="demo-ruleForm"
                    :size="formSize"
                    status-icon
            >
                <el-form-item label="课程名" prop="name">
                    <el-input v-model="ruleForm.name"/>
                </el-form-item>
                <el-form-item label="课程介绍" prop="intro">
                    <el-input v-model="ruleForm.intro"
                              type="textarea"/>
                </el-form-item>

                <el-form-item label="课程类别" prop="type">
                    <el-radio-group v-model="ruleForm.type">
                        <el-radio border label="理学"/>
                        <el-radio border label="工学"/>
                        <el-radio border label="文学"/>
                        <el-radio border label="法学"/>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="课程标签" type="">
                    <el-tag
                            v-for="tag in dynamicTags"
                            :key="tag"
                            class="mx-1"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(tag)"
                    >
                        {{ tag }}
                    </el-tag>
                    <el-input
                            v-if="inputVisible"
                            ref="InputRef"
                            v-model="inputValue"
                            class="ml-1 w-20"
                            size="small"
                            @keyup.enter="handleInputConfirm"
                            @blur="handleInputConfirm"
                    />
                    <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput">
                        + 新增标签
                    </el-button>
                </el-form-item>
                <div>
                    <el-form-item label="封面上传" prop="cover">
                        <el-upload

                                ref="uploadCoverRef"
                                class="upload-demo"
                                list-type="picture"
                                drag
                                name="videoCover"
                                multiple
                                accept=".jpeg,.png,.jpg"
                                :limit="1"
                                :auto-upload="false"
                                :on-change="getCover"
                                :on-remove="removeCover"
                                :on-preview="handlePictureCardPreview"

                        >
                            <el-icon class="el-icon--upload">
                                <upload-filled/></el-icon>
                            <div class="el-upload__text">
                                拖拽 或 <em>点击上传封面</em>
                            </div>
                            <template #tip>
                                <div class="el-upload__tip">
                                    封面文件最大2MB，格式为jpeg/jpg/png
                                </div>
                            </template>
                        </el-upload>

                        <el-dialog v-model="dialogVisible">
                            <el-image  :src="dialogImageUrl" fit="fill" />
                        </el-dialog>
                    </el-form-item>
                </div>

                <el-form-item label="精品设置">
                    <el-radio-group v-model="ruleForm.vip">
                        <el-radio border label="ORDINARY"/>
                        <el-radio border label="VIP"/>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitUpload">上传</el-button>

                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script setup>
    import {reactive, ref, nextTick,onMounted} from 'vue'
    import {FormInstance, FormRules, ElInput, UploadProps, UploadUserFile, ElMessage, ElMessageBox} from 'element-plus'
    import {UploadFilled,} from '@element-plus/icons-vue'
    import {useStore} from 'vuex'
    import {loadNewCourse} from '@/api/course'
    import {useRouter} from 'vue-router'
    import {addNewTextualMaterial} from "@/api/textualmaterial";

    const props = defineProps(['materialType'])//获取父组件传值，video代表是视频文件，text代表是文档文件
    const materialType = props.materialType;
    const is_video = ref(false)

    //tag课程类别变量声明
    const inputValue = ref('')
    const dynamicTags = ref([])
    const inputVisible = ref(false)
    const InputRef = ref()
    //视频或者文档上传变量
    const is_uploading = ref(false);
    // const videoUploadPercent = ref(0);
    const uploadRef = ref();
    // const action = ref('');
    const accept=ref('');
    const loading = ref(false);

    const router = useRouter();
    const dialogVisible = ref(false);
    const dialogImageUrl = ref('');

    //表单验证和表单数据处理
    const store = useStore();
    const formSize = ref('large')
    const labelPosition = ref('top')
    const ruleFormRef = ref()
    //表单包含的信息
    const ruleForm = reactive({
        name: '',
        type: '',
        tid: store.getters.getId,
        videoFile: {},
        textualMaterialFile:{},
        label: '',
        vip: 'ORDINARY',
        intro: '',
        videoCover: {},
        textualType:"",//文档类型
    })
    onMounted(()=>{
        if (materialType == 'video'){
            // action.value = '/api/course/load'
            accept.value = '.mp4';
            is_video.value = true;
        }else if (materialType == 'text') {
            // action.value = '';
            accept.value = '.docx,.pdf';
            is_video.value = false;
        }
    })

    const checkUploadFile = (rawFile) => {
        if (is_video){
            if (rawFile.size / 1024 / 1024 > 150 ) {
                ElMessage.warning('视频大小不能超过150MB');
                return false;
            }
        }else {
            if (rawFile.size / 1024 / 1024 > 1 ) {
                ElMessage.warning('文档大小不能超过1MB');
                return false;
            }
        }
        return true;
    }

    const onUpload = (rawFile,rawFiles)=>{
        if (!checkUploadFile(rawFile)){
            uploadRef.value.clearFiles();
            return ;
        } else {
            is_uploading.value = true;
            // videoUploadPercent.value = 0;
            ruleForm.name = rawFile.name.substring(0,rawFile.name.lastIndexOf("."));
            if (is_video.value) {
                ruleForm.videoFile = rawFile.raw;
            }else {
                ruleForm.textualMaterialFile = rawFile.raw;
                ruleForm.textualType = rawFile.name.substring(rawFile.name.lastIndexOf(".")+1).toLowerCase();
                console.log(ruleForm.textualType)
            }
        }
    }
    //cover的大小校验
    const beforeCoverUpload = (rawFile) => {
        if (rawFile.size / 1024 / 1024 > 1) {
            ElMessage.warning('封面大小不能超过1MB');
            return false;
        }
        return true;
    };
    //获取cover文件
    const getCover = (rawFile) => {
        if (!beforeCoverUpload(rawFile)){
            uploadCoverRef.value.clearFiles();
            return ;
        }else {
            if (rawFile.status === 'ready'){
                ruleForm.videoCover = rawFile.raw;
            }
        }
    }

    // const removeFile = () => {
    //     ruleForm.videoFile = {};
    // }
    const removeCover = () => {
        ruleForm.videoCover = {};
    }
    const handlePictureCardPreview = (uploadFile)=>{
        // console.log(uploadFile)
        dialogImageUrl.value = uploadFile.url
        dialogVisible.value = true
    }

    //表单验证
    const rules = reactive({
        name: [
            {required: true, message: '请填写课程名', trigger: 'blur'},
            // {pattern:/^\S+$/,message: '昵称不能有空格',trigger: 'change'}
        ],
        type: [
            {
                required: true,
                message: '至少选择一个分类',
                trigger: 'change',
            },
        ],
        intro: [
            {required: true, message: '请填写课程简介', trigger: 'blur'},
            {min: 5, max: 20, message: '介绍长度在5-20个字符之间', trigger: 'blur'},
            {pattern:/^\S+$/,message: '介绍不能有空格',trigger: 'change'}
        ],


    })




    // const checkUploadFile = (videoFile, coverFile) => {
    //     return beforeCourseUpload(videoFile) && beforeCoverUpload(coverFile)
    // }
    const uploadCoverRef = ref();

    //重置表单
    const resetForm = () => {
        //表单其它内容重置
        ruleFormRef.value.resetFields()
        //tag标签重置
        dynamicTags.value = []
        //单选框重置
        ruleForm.type = ''
        ruleForm.textualType = '';
        //视频重置
        ruleForm.videoFile={};
        ruleForm.textualMaterialFile ={};
        //封面重置
        uploadCoverRef.value.clearFiles();
        ruleForm.videoCover = {};
    }
    //关闭表单重置
    // const closeReset = () => {
    //     resetForm();
    // }

    //表单提交
    const submitUpload = () => {
        if ( JSON.stringify(ruleForm.videoCover) == '{}'){
            ElMessage.error("请上传封面")
        }else {
            ruleFormRef.value.validate((valid) => {
                if (valid){
                    ElMessageBox.confirm(
                        '是否确认上传课程',
                        '警告',
                        {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }
                    ).then(() => {
                            ElMessage.warning("文件上传中请勿进行其他操作，等待自动跳转")
                            ruleForm.label = dynamicTags.value.join('&')
                            loading.value = true;
                            if (is_video.value){
                                loadNewCourse(ruleForm).then((res) => {
                                        ElMessage.success("上传成功等待审核")
                                        loading.value = false;
                                        resetForm()
                                        is_uploading.value = false;
                                    }
                                )
                            }else {
                                addNewTextualMaterial(ruleForm).then((res)=>{
                                    ElMessage.success("上传成功等待审核")
                                    loading.value = false;
                                    resetForm()
                                    is_uploading.value = false;
                                })
                            }

                        }).catch(() => {
                            ElMessage({
                                type: 'info',
                                message: '取消操作',
                            })
                        })
                }
            })
        }

    }

    //tag标签处理

    const handleClose = (tag) => {
        dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1)
    }

    const showInput = () => {
        inputVisible.value = true
        nextTick(() => {
            InputRef.value.input.focus()
        })
    }

    const handleInputConfirm = () => {
        if (inputValue.value.trim()) {
            dynamicTags.value.push(inputValue.value)
        }
        inputVisible.value = false
        inputValue.value = ''
    }


</script>

<style scoped>


    .uploadDiv{
        margin-left: 10%;
        margin-right: 10%;
    }
    .info_class{
        margin-left: 10%;
        margin-right: 10%;
    }
    .upload-demo{
        width: 100%;
    }
</style>
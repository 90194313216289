import request from '@/utils/request'
export function loadNewCourse(data) {
    return request({
        url: '/api/course/load',
        method: 'post',
        headers: {'Content-Type':'multipart/form-data'},
        data
    })
}
//删除课程
export function deleteCourse(data) {
    return request({
        url: `/api/course/deleteCourse/${data}`,
        method: 'post',
    })
}
export function deleteTextual(data) {
    return request({
        url: `/api/textualMaterial/deleteTextual/${data}`,
        method: 'post',
    })
}

//更新状态为待删除
export function updateIsDelete(data) {
    return request({
        url: `/api/course/updateIsDelete/${data}`,
        method: 'post',
    })
}
//更新状态为未删除
export  function updateIsDelete2Zero(data) {
    return request({
        url: `/api/course/updateIsDelete2Zero/${data}`,
        method: 'post',
    })
}

//编辑框回显数据
export function editCourse(data) {
    return request({
        url: `/api/course/editCourse/${data}`,
        method: 'post',
    })
}
//编辑框更新数据
export function updateCourse(data) {
    return request({
        url: '/api/course/updateCourse',
        //post请求
        method: 'post',
        data
    })
}

export function updateIsExamine(data) {
    return request({
        url:`/api/course/updateIsExamine/${data}`,
        method: 'post',
    })
}


export function courseOrdinaryCount() {
    return request({
        url: '/api/course/courseOrdinaryCount',
        //post请求
        method: 'post',
    })
}

export function courseVipCount() {
    return request({
        url: '/api/course/courseVipCount',
        //post请求
        method: 'post',
    })
}

export function courseOrdinaryCountByTid(data) {
    return request({
        url: `/api/course/courseOrdinaryCountByTid/${data}`,
        method: 'post'
    })
}

export function courseVipCountByTid(data) {
    return request({
        url: `/api/course/courseVipCountByTid/${data}`,
        method: 'post'
    })
}

export function textOrdinaryCountByTid(data) {

}


export function selectOrdinaryCourse() {
    return request({
        url: '/api/course/selectOrdinaryCourse',
        method: 'post',
    })
}

export function selectOrdinaryCourseWithPage(data) {
    return request({
        url: '/api/course/selectOrdinaryCourseWithPage',
        method: 'post',
        data,
    })
}

export function selectVipCourse() {
    return request({
        url: '/api/course/selectVipCourse',
        method: 'post',
    })
}

export function selectVipCourseWithPage(data) {
    return request({
        url: '/api/course/selectVipCourseWithPage',
        method: 'post',
        data,
    })
}

export function selectEngineeringCourse() {
    return request({
        url: '/api/course/selectEngineeringCourse',
        method: 'post',
    })
}

export function selectEngineeringCourseWithPage(data) {
    return request({
        url: '/api/course/selectEngineeringCourseWithPage',
        method: 'post',
        data,
    })
}

export function selectScienceCourse() {
    return request({
        url: '/api/course/selectScienceCourse',
        method: 'post',
    })
}

export function selectScienceCourseWithPage(data) {
    return request({
        url: '/api/course/selectScienceCourseWithPage',
        method: 'post',
        data,
    })
}

export function selectLiteratureCourse() {
    return request({
        url: '/api/course/selectLiteratureCourse',
        method: 'post',
    })
}

export function selectLiteratureCourseWithPage(data) {
    return request({
        url: '/api/course/selectLiteratureCourseWithPage',
        method: 'post',
        data,
    })
}

export function selectLawCourse() {
    return request({
        url: '/api/course/selectLawCourse',
        method: 'post',
    })
}

export function selectLawCourseWithPage(data) {
    return request({
        url: '/api/course/selectLawCourseWithPage',
        method: 'post',
        data,
    })
}

export function selectAllCourse() {
    return request({
        url: '/api/course/selectAllCourse',
        method: 'post',
    })
}



export function selectAllCourseWithPage(data) {
    return request({
        url: '/api/course/selectAllCourseWithPage',
        method: 'post',
        data,
    })
}

